import React from 'react';

import cx from 'classnames';
import { Link } from 'gatsby';
import { TFunction } from 'react-i18next';
import { DataCols } from 'src/components/admin-table/admin-table';
import { TypedFilterObj } from 'src/components/filter-bar/types';
import { checklistStatusMap, defaultFilterSpacing } from 'src/components/organisations/constants';
import { VIEWING_CONTEXT_MAP } from 'src/components/organisations/organisation-profile/config';
import { ChecklistStatusLabel } from 'src/components/organisations/status-labels';
import { OrgProfileReferrer } from 'src/components/organisations/types';
import { ChecklistStatus } from 'src/types/classic';
import { GetTenantChecklists } from 'src/types/classic-queries';
import { buildFiltersConfig, insertRegionFilter } from 'src/utils/helper/filtersConfigUtils';
import ROUTES from 'src/utils/routes';

import * as styles from './checklists.module.less';
import { MIN_SEARCH_CHARS } from './constants';
import { ChacklistsTableItem, CreateConfigOptions, CreateFiltersOptions } from './types';

const makeStatusOptions = (t: TFunction) => {
  const defaultOptions = [{ label: t('any status'), value: '' }];
  const options = Object.entries(checklistStatusMap).map(([key, { transKey }]) => ({
    label: t(transKey),
    value: `${key}`,
  }));

  return defaultOptions.concat(options);
};

const makeChecklistOptions = (data?: GetTenantChecklists) => {
  const tenantChecklists = data?.tenantChecklists ?? [];
  return tenantChecklists.map((tc) => ({ label: tc.Name, value: tc.Tag }));
};

export const createFilters = ({
  t,
  regionConfig,
  viewingContext,
  tenantChecklistData,
  selectedFacilityId,
}: CreateFiltersOptions) => {
  const defaultStatus = `${ChecklistStatus.AWAITING_REVIEW}`;
  const statusOptions = makeStatusOptions(t);
  const checklistOptions = makeChecklistOptions(tenantChecklistData);
  const hideMultiSelectChecklistFilter = !regionConfig || viewingContext !== VIEWING_CONTEXT_MAP.REGIONAL;

  const filters: (TypedFilterObj & { initialValue: any })[] = [
    {
      key: 'search',
      type: 'search',
      initialValue: '',
      props: {
        placeholder: t('organisationsModule.filters.searchPlaceholderChecklists'),
        delay: 200,
        minCharsTooltip: { minChars: MIN_SEARCH_CHARS },
        classNames: { wrapper: styles.searchWrapper, input: styles.search },
      },
      spacing: defaultFilterSpacing,
    },
    {
      key: 'checklist',
      type: 'select',
      initialValue: undefined,
      props: {
        placeholder: t('select checklist'),
        options: checklistOptions,
        disabled: !checklistOptions.length,
      },
      spacing: defaultFilterSpacing,
      hide: !hideMultiSelectChecklistFilter,
    },
    {
      key: 'multiChecklist',
      type: 'multi-select',
      initialValue: [],
      props: {
        placeholder: (val: string[]) => {
          if (!val.length) return t('any checklist');
          if (val.length === 1) return checklistOptions.find((opt) => opt.value === val[0])?.label as string;

          return t('placeholderCount', { type: 'checklists', count: val.length });
        },
        options: checklistOptions,
        withChevron: true,
        disabled: !checklistOptions.length,
      },
      spacing: defaultFilterSpacing,
      hide: hideMultiSelectChecklistFilter,
    },
    {
      key: 'status',
      type: 'select',
      initialValue: defaultStatus,
      props: {
        options: statusOptions,
        classNames: {
          trigger: styles.filterWidth,
        },
      },
      spacing: defaultFilterSpacing,
    },
  ];

  if (regionConfig) {
    const { insertAtIndex, transKeyDefaultOption } = regionConfig.filter;
    insertRegionFilter(filters, {
      defaultOptionLabel: t(transKeyDefaultOption),
      selectedFacilityId,
      insertAtIndex,
      viewingContext,
      visibility: [VIEWING_CONTEXT_MAP.GLOBAL, VIEWING_CONTEXT_MAP.NATIONAL],
    });
  }

  return buildFiltersConfig(filters);
};

export const createColumns = ({ t, regionConfig }: CreateConfigOptions) => {
  const columns: DataCols<ChacklistsTableItem> = [
    {
      key: 'Venue.Name',
      title: t('club name'),
      getValue: (item) => (
        <>
          <Link to={`${ROUTES.MANAGE_ORGANISATIONS}/${item?.Venue?.ID}?referrer=${OrgProfileReferrer.CHECKLISTS}`}>
            {item?.Venue?.Name}
          </Link>
          {item?.Venue?.FriendlyID && <span>{item?.Venue?.FriendlyID}</span>}
        </>
      ),
      widthClassName: cx(styles.tableCell, styles.organisationNameCell),
    },
    {
      key: 'checklist',
      title: t('checklist'),
      getValue: (item) => item?.ChecklistState?.Checklist?.Name,
      sort: false,
      widthClassName: styles.tableCell,
    },
    {
      key: 'status',
      title: t('status'),
      getValue: (item) =>
        typeof item?.ChecklistState.Status === 'number' ? (
          <ChecklistStatusLabel status={item?.ChecklistState?.Status} />
        ) : (
          t('n/a')
        ),
      sort: false,
      widthClassName: styles.tableCell,
    },
  ];

  if (regionConfig) {
    const { transKeyTitle, insertAtIndex } = regionConfig.column;
    const regionColumn = {
      key: 'region',
      title: t(transKeyTitle),
      getValue: (item) => item?.Venue?.County,
      sort: false,
      widthClassName: styles.tableCell,
    };

    columns.splice(insertAtIndex, 0, regionColumn);
  }

  return columns;
};
