import React, { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { classicRestClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import EmptyState from 'src/components/empty-state/empty-state';
import { VIEWING_CONTEXT_MAP } from 'src/components/organisations/organisation-profile/config';
import { useTenantChecklists, useViewingContext } from 'src/components/organisations/organisation-profile/hooks';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import Spinner from 'src/components/spinner/spinner';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getRootProviderId } from 'src/config/config';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import { GET_VENUE_CHECKLISTS } from 'src/queries/REST/organisations';
import { GetVenueChecklists } from 'src/types/classic-queries';
import { SORT_DIRECTION } from 'src/utils/constants/classic';
import { defaultPageTitleTransKeys } from 'src/utils/constants/labels';
import { TABLE_IDS } from 'src/utils/constants/tables';
import { mapToClassicSorts } from 'src/utils/helper/sortOptions';

import { MIN_SEARCH_CHARS } from './constants';
import { ChacklistsTableItem, ChecklistsProps } from './types';
import { createColumns, createFilters } from './utils';

const useQFT = initQueryFilterTableHook<GetVenueChecklists, ChacklistsTableItem>(GET_VENUE_CHECKLISTS);

const Checklists = ({
  regionConfig,
  transKeyTitle = defaultPageTitleTransKeys.ORGANISATION_CHECKLISTS,
}: ChecklistsProps) => {
  const { t } = useTranslation();
  const selectedFacilityId = useOrgId();
  const tenantId = getRootProviderId();
  const { viewingContext } = useViewingContext() ?? {};
  const { data: tenantChecklistData } = useTenantChecklists();
  const hideMultiSelectChecklistFilter = !regionConfig || viewingContext !== VIEWING_CONTEXT_MAP.REGIONAL;
  const filters = useMemo(
    () =>
      createFilters({
        t,
        viewingContext,
        regionConfig,
        tenantChecklistData,
        selectedFacilityId,
      }),
    [t, viewingContext, regionConfig, tenantChecklistData, selectedFacilityId],
  );

  const columns = useMemo(
    () => createColumns({ t, regionConfig, selectedFacilityId }),
    [t, regionConfig, selectedFacilityId],
  );

  const getQueryVarsFromContext = useCallback(
    (ctx) => {
      const { limit, offset } = ctx.paging;
      const { checklist, multiChecklist, region, search, status } = ctx.filters;
      const checklistFilter = hideMultiSelectChecklistFilter ? [checklist].filter(Boolean) : multiChecklist;
      const statusFilter = status ? Number(status) : undefined;
      const defaultSorting = { Property: 'Venue.Name', SortDirection: SORT_DIRECTION.ASCENDING };
      const searchFilter = typeof search === 'string' && search.length >= MIN_SEARCH_CHARS ? search : undefined;

      return {
        input: {
          VenueID: tenantId,
          RegionID: region || undefined,
          Status: statusFilter,
          SearchTerm: searchFilter,
          ChecklistTags: checklistFilter.length ? checklistFilter : undefined,
          Limit: limit,
          Offset: offset,
          Sort: ctx.sorting ? mapToClassicSorts(ctx.sorting)[0] : defaultSorting,
        },
      };
    },
    [tenantId, hideMultiSelectChecklistFilter],
  );

  const {
    components: { FilterBar, AdminTable, AccessoryBar, props },
    query: { error: tableError },
    controls: { filtersValues },
  } = useQFT(
    filters,
    {
      columns,
      tableProps: {
        tableId: TABLE_IDS.CHECKLISTS_LIST,
        rowsPerPageOptions: [25, 50],
      },
    },
    {
      columnSelectorId: TABLE_IDS.CHECKLISTS_LIST,
      mapFiltersToQueryOptions: ({ filters }) => {
        return {
          skip: !viewingContext || (hideMultiSelectChecklistFilter && !filters['checklist']),
          client: classicRestClient,
          getTotalItems: (data) => data?.venueChecklists?.Total ?? 0,
          transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
            const { limit, offset, sorts } = vars;

            return getQueryVarsFromContext({
              filters,
              paging: { limit, offset },
              sorting: sorts,
            });
          },
          tableId: TABLE_IDS.CHECKLISTS_LIST,
          defaultFetchLimit: 25,
          fetchPolicy: 'no-cache',
        };
      },
      mapDataToTable: (data) => {
        const items = data?.venueChecklists?.Checklists ?? [];
        return items.map((item) => ({
          id: `${item?.Venue.ID}-${item?.ChecklistState?.Checklist?.Tag}`,
          ...item,
        }));
      },
      depsFilterConfig: [filters],
      depsTableConfig: [columns],
    },
  );

  const renderEmptyState = hideMultiSelectChecklistFilter && typeof filtersValues['checklist'] === 'undefined';

  if (!viewingContext) return <Spinner fullScreen />;

  return (
    <PageMaxWidth>
      <Grid container>
        <PageHeader title={t(transKeyTitle)} />
      </Grid>
      <Panel headerEndContent={<AccessoryBar {...props.accessoryBar} />} extendedPadding>
        <FilterBar {...props.filterBar} defaultValues={{ ...props.filterBar.values, status: '' }} withClearBtn />
        {renderEmptyState ? (
          <EmptyState title={t('select checklist')} subtitle={t('select a checklist to start')} icon="xl-checklists" />
        ) : (
          <AdminTable
            {...props.adminTable}
            error={tableError && t('organisationsModule.queryErrors.getVenueChecklists')}
          />
        )}
      </Panel>
    </PageMaxWidth>
  );
};

export default Checklists;
