import { getClientConfig } from 'src/config/config';
import { ClientPropsConfig, PropsFrom } from 'src/types';

import Checklists from './checklists';

type OverridableProps = PropsFrom<typeof Checklists>;

const clientProps: ClientPropsConfig<OverridableProps> = {
  FA: {
    regionConfig: {
      filter: { insertAtIndex: 1, transKeyDefaultOption: 'any approver' },
      column: { insertAtIndex: 1, transKeyTitle: 'county approver' },
    },
  },
  DEFAULT: {},
};

export type ConfigOptions = keyof typeof clientProps;

export const getClientProps = () => {
  const { organisationModule } = getClientConfig();
  const setting = organisationModule?.checklistsPage ?? '';
  return (clientProps[setting] ?? clientProps.DEFAULT) as OverridableProps;
};
